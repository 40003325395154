// footer.js
import React from "react";
import "./Footer.css";
import { SocialMediaMenu } from "../social-media/social-media-menu"; // Make sure to create a corresponding CSS file

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <section className="footer-content">
          {/*<div className="footer-section">*/}
          {/*    <h3>Dundee Argentine Tango</h3>*/}
          {/*    <p>Join us for a night of dance and passion at Tayside Tango.</p>*/}
          {/*</div>*/}
          {/*<div className="footer-section">*/}
          {/*    <h4>Contact Us</h4>*/}
          {/*    <p>Email: contact@taysidetango.com</p>*/}
          {/*    <p>Phone: +1 234 567 890</p>*/}
          {/*</div>*/}
          <div className="footer-section">
            <h4>Follow us on social media</h4>
            <SocialMediaMenu color={"var(--light-pink)"} />
          </div>
        </section>
        <section className="footer-bottom">
          <p>
            © {new Date().getFullYear()} Dundee Argentine Tango. All Rights
            Reserved.
          </p>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
