import React, { useState } from "react";
import { ref, set } from "firebase/database";
import database from "../../firebase";
import "./newsletter-signup.scss";

interface NewsletterSignupProps {
  onSuccess: () => void;
}

const NewsletterSignup: React.FC<NewsletterSignupProps> = ({ onSuccess }) => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<Boolean>(false);

  function sanitizeEmail(email: string): string {
    return email.replace(/[.@_+-:]/g, "-");
  }

  function validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function sendEmail() {
    if (email && validateEmail(email)) {
      set(ref(database, "emails/" + sanitizeEmail(email)), {
        value: email,
      });
      onSuccess();
      setEmailError(true);
    } else {
      setEmailError(true);
    }
  }

  return (
    <div className={"newsletter-signup-container"}>
      <input
        type="email"
        className="email-input"
        placeholder="Type your email..."
        onChange={(event) => {
          setEmail(event.target.value);
          setEmailError(false);
        }}
      />
      <div
        className="email-error-message"
        style={
          emailError ? { visibility: "visible" } : { visibility: "hidden" }
        }
      >
        {"Please enter a valid email."}
      </div>
      <button onClick={sendEmail} className="button email-btn">
        Sign me up for updates 😎
      </button>
    </div>
  );
};

export default NewsletterSignup;
