import React from "react";
import "./EventCard.scss";

const EventCard = ({
  title,
  date,
  location,
  price,
  organiser,
  dj,
  teacher,
  eventLink,
}) => {
  return (
    <div className="event-card">
      {/*<img*/}
      {/*    className="event-image"*/}
      {/*    src="https://live.staticflickr.com/65535/52500955898_14618bb677_b.jpg"*/}
      {/*    alt="Event"*/}
      {/*/>*/}

      <div className="event-card-content">
        <div className={"top-things"}>
          <p className="event-title">{title}</p>
          <p className="event-dj">{dj}</p>
          {teacher && <p className="event-teacher">taught by {teacher}</p>}
        </div>
        <div className={"bottom-things"}>
          <p className="event-date">{date}</p>
          <p className="event-location">{location}</p>
          <p className="event-price">{price || "Free"}</p>
          {eventLink && (
            <>
              <div style={{ marginBottom: "10px" }} />
              <a
                href={eventLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: "100%", color: "" }}
              >
                Link to FB Event
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
