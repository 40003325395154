import React from "react";
import "./button.scss";

type ButtonProps = {
  title: string;
  href: string;
  primary?: boolean;
  newWindowLink?: boolean;
  styleType?: "primary" | "secondary" | "tertiary"; // Add new prop for style type
};

export const Button: React.FC<ButtonProps> = ({
  title,
  href,
  primary,
  newWindowLink,
  styleType = "primary", // Default style type
}) =>
  primary ? (
    <a
      className="link"
      href={href}
      {...(newWindowLink && { target: "_blank", rel: "noopener noreferrer" })}
    >
      <button className={`action-button ${styleType}`}>{title}</button>
    </a>
  ) : (
    <a
      className="link"
      href={href}
      {...(newWindowLink && { target: "_blank", rel: "noopener noreferrer" })}
    >
      {title}
    </a>
  );
