import React from "react";

export const LinkDetails = ({ icon, title, sections }) => {
  return (
    <details className="question-answer-container">
      <summary className={"question"}>
        {icon} {title}
        <span className="icon">👇</span>
      </summary>
      <div className={"answers"}>
        {sections.map((section, index) => (
          <React.Fragment key={index}>
            {section.title && <b>{section.title}</b>}
            {section.links.map((link, linkIndex) => (
              <a
                key={linkIndex}
                href={link.url}
                target="_blank"
                className={"answer"}
              >
                {link.text}
              </a>
            ))}
            <div style={{ margin: "10px" }}></div>
          </React.Fragment>
        ))}
      </div>
    </details>
  );
};
