// about.js
import React from "react";
import "../about/About.scss";
import { ScrollContainer } from "react-indiana-drag-scroll";
import "react-indiana-drag-scroll/dist/style.css";

function Photos({ showHeading }) {
  // List of image sources
  const imageSources2 = [
    "./members/19.jpg",
    "./members/18.jpg",
    "./members/21.jpg",
    "./members/20.jpg",
    "./members/16.jpg",
    "./members/22.jpg",
    "./members/15.jpg",
    "./members/3.jpg",
    "./members/7.jpg",
    "./members/17.jpg",
    "./members/6.jpg",
    "./members/12.jpg",
    "./members/14.jpg",
    "./members/0.jpg",
    "./members/11.jpg",
    "./members/13.jpg",
    "./members/1.jpg",
    "./members/5.jpg",
  ];

  const imageSources = [
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552633_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664795075_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552833_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664693719_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552763_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664693644_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53663466882_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664551008_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/91ec00a0-f857-49e1-a96a-11ae7bd0c2d9.JPEG",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53663466902_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53663465202_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664339496_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53663466907_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664326676_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53663454562_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664326746_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664326791_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664337866_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53663454622_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664339386_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664339396_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664339466_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664339471_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664339481_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664539998_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664540088_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552658_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552663_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552733_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552758_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552793_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664552828_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664680634_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664680674_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664680724_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664680734_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664680759_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664680774_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664680804_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664680819_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664691989_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664693559_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664693614_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664782755_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664782850_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664782860_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664793440_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664795130_o.jpg",
    "https://dtodorowa.github.io/dat-pics/images/dundee-argentine-tango_53664795195_o.jpg",
  ];

  return (
    <section className="photos-container" id="photos">
      {showHeading && <h2>People ❤️</h2>}
      <ScrollContainer>
        <div className="carousel">
          <div className="items">
            {imageSources.map((src, index) => (
              <img
                key={index}
                alt="Adults learning Argentine Tango in Dundee dance class"
                className="item"
                src={src}
              />
            ))}
          </div>
        </div>
      </ScrollContainer>
    </section>
  );
}

export default Photos;
