import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5BGvCOMpaUe_UgiwLqKSiZGVEbmypT5Q",
  authDomain: "dundee-argentine-tango.firebaseapp.com",
  databaseURL:
    "https://dundee-argentine-tango-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dundee-argentine-tango",
  storageBucket: "dundee-argentine-tango.appspot.com",
  messagingSenderId: "852361515220",
  appId: "1:852361515220:web:09541af683cae0ccd8eb41",
  measurementId: "G-MJRFKTVXHY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export default database;
