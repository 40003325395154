import Header from "./header/Header";
import Faq from "./faq/Faq";
import Footer from "./footer/Footer";
import "./main-page.scss";
import Photos from "./photos/Photos";
import ComingUp from "./coming-up/ComingUp";
import UsefulLinks from "./useful-links/UsefulLinks";
import { LandingPageTile } from "./landing-page/landing-page-tile";
import About from "./about/About";

//todo: add those to a db and retrieve from there?
//todo: add a whole faq page, similar to documentation
const questions = [
  {
    id: 1,
    question: "Do I need a dance partner to join?",
    answer:
      "No, bringing a partner is not required. We welcome solo participants. In our course, everyone actively learns to lead and follow, and we will regularly swap partners and roles 😊",
  },
  {
    id: 2,
    question: "What kind of shoes should I wear for tango dancing?",
    answer:
      "Ideally, wear comfortable shoes with smooth soles that allow for pivoting. Tango shoes with suede or leather soles are great, but you can start with any comfortable shoes that provide good support. If you're unsure, feel free to ask us for recommendations and tips 🤗",
  },
  {
    id: 4,
    question:
      "How do I stay updated with the latest tango events and community news?",
    answer:
      "To stay in the loop, follow us on social media and/or join our mailing list. We regularly post updates on upcoming events, workshops, and more!",
  },
  {
    id: 5,
    question: "Is there a dress code for tango events?",
    answer:
      "There's no strict dress code, but many dancers choose to wear comfortable clothing that allows ease of movement. Feel free to express your style 👯",
  },
  {
    id: 6,
    question: "Do I need prior dance experience to join the community?",
    answer:
      "No prior dance experience is required. We welcome everyone, whether you're a seasoned dancer or have never danced before in your life. Our community is inclusive and supportive, and we encourage everyone to join in the fun and learn together ❤️",
  },
  {
    id: 8,
    question: "How can I get involved and contribute to the tango community?",
    answer:
      "We love enthusiastic members! You can get involved by volunteering, helping with event organization, or even sharing your passion for tango with others. Reach out to us to explore opportunities.",
  },
];

function HomePage() {
  return (
    <div className="main-page">
      <Header />
      <main>
        <LandingPageTile
          title={"Hello"}
          paragraph={"sfsdf"}
          imageUrl={"hello"}
        />
        <UsefulLinks />
        <ComingUp />
        <Faq data={questions} />
        <Photos showHeading />
      </main>
      <Footer />
    </div>
  );
}

export default HomePage;
