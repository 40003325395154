// about.js
import React, { useState } from "react";
import "./About.scss"; // Make sure to create a corresponding CSS file

import { ref, set } from "firebase/database";
import database from "../../firebase";
import NewsletterSignup from "./newsletter-signup";

function About() {
  const [email, setEmail] = useState(undefined);
  const [signedUpForNewsletter, setSignedUpForNewsletter] = useState(false);
  const [emailError, setEmailError] = useState("");

  function sanitizeEmail(email) {
    return email.replace(/[.@_+-:]/g, "-");
  }

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function sendEmail() {
    if (validateEmail(email)) {
      set(ref(database, "emails/" + sanitizeEmail(email)), {
        value: email,
      });
      setSignedUpForNewsletter(true);
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email.");
    }
  }

  return (
    <section className="about" id="about">
      <h2>About us</h2>
      <p>
        We're a community-driven crew of local social dancers who would like to
        share the joy of Argentine Tango with the city of Dundee 💃🕺 Our
        community is led by a dedicated team of lovely humans who bring years of
        tango experience to the table. Each member of our team is passionate
        about tango and equally enthusiastic about sharing their knowledge with
        others.
      </p>
      <p>
        We encourage you to get involved and take part in what we do. Whether
        you're a curious beginner or an experienced dancer, there's a place for
        you here. Join us in spreading good vibes and creating awesome social
        dance opportunities ✨
      </p>
      <p>
        Follow us on social media, so you don't miss out on our upcoming events
        💖 <br />
        If social media is not how you roll, drop your email down below and
        we'll keep you updated.
      </p>
      {!signedUpForNewsletter ? (
        <NewsletterSignup onSuccess={() => setSignedUpForNewsletter(true)} />
      ) : (
        <>
          🎉🎉🎉 <br />
          Thanks for signing up! You'll hear from us soon 😎
        </>
      )}

      {!signedUpForNewsletter ? (
        <>
          <input
            type={"email"}
            className="email-input"
            placeholder={"Type your email..."}
            onChange={(event) => {
              setEmail(event.target.value);
              setEmailError("");
            }}
          />
          {emailError && (
            <div className="email-error-message">{emailError}</div>
          )}

          <button onClick={sendEmail} className="button email-btn">
            Sign me up for updates 😎
          </button>
        </>
      ) : (
        <>
          🎉🎉🎉 <br />
          Thanks for signing up! You'll hear from us soon 😎
        </>
      )}
    </section>
  );
}

export default About;
