import React from "react";
import "./social-media-menu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

interface SocialMediaMenuProps {
  color: string;
}

export const SocialMediaMenu: React.FC<SocialMediaMenuProps> = ({ color }) => {
  return (
    <div
      className="social-container"
      style={{ "--icon-color": color } as React.CSSProperties}
    >
      <a
        href="https://instagram.com/dundeeargentinetango"
        className="instagram social"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a
        href="https://facebook.com/dundee.argentine.tango"
        className="facebook social"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a
        href="https://www.youtube.com/@DundeeArgentineTango/playlists"
        className="youtube social"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
    </div>
  );
};
