import React from "react";
import "./header.scss";
import { Button } from "../ui/button";

function Header() {
  return (
    <div className="header-container" id={"welcome"}>
      <header className="header">
        <h1>Dundee Argentine Tango</h1>
        <p>
          Join us to experience the joy of dancing Argentine Tango and connect
          with a vibrant community of dancers in Dundee.
          <br /> Everyone is welcome!
        </p>

        <Button
          className={"action-button"}
          title={"Check out our upcoming events"}
          href={"#comingUp"}
          primary
          styleType={"tertiary"}
        />

        {/*<nav className="navigation">*/}
        {/*  <ul>*/}
        {/*    <li>*/}
        {/*      <a href="#about">about</a>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <a href="#faq">faq</a>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <a href="#updates">Updates</a>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <a href="#register">Register</a>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</nav>*/}
      </header>
    </div>
  );
}

export default Header;
