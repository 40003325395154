import React from "react";
import "./UsefulLinks.scss";
import { LinkDetails } from "./LinkDetails";

function UsefulLinks() {
  const tangoShoesData = [
    {
      title: "Affordable Practice Shoes",
      links: [
        {
          text: "Nagar Dancing Shoes. Our recommendation for beginners",
          url: "https://www.nagardancingshoes.co.uk/store/",
        },
      ],
    },
    {
      title: "Female Tango Shoes",
      links: [
        { text: "Fulana Oxford Shoes", url: "https://fulanashoes.com/oxford/" },
        { text: "Fulana Boots", url: "https://fulanashoes.com/boots/" },
        { text: "Fulana Vegan Shoes", url: "https://fulanashoes.com/vegan/" },
        {
          text: "Regina Shoes",
          url: "https://www.reginatangoshoes.com/en/woman-shoes.html",
        },
        {
          text: "Madame Pivot",
          url: "https://madamepivot.shop/en/36-tango-shoes",
        },
        {
          text: "Madame Pivot Vegan",
          url: "https://madamepivot.shop/en/47-vegan-shoes",
        },
        {
          text: "Yuyo Brujo",
          url: "https://www.yuyobrujo.com/tango-shoes-for-women/",
        },
      ],
    },
    {
      title: "Male Tango Shoes",
      links: [
        {
          text: "Regina Shoes",
          url: "https://www.reginatangoshoes.com/en/man-shoes.html",
        },
        {
          text: "Madame Pivot",
          url: "https://madamepivot.shop/en/42-tango-shoes",
        },
        {
          text: "Yuyo Brujo",
          url: "https://www.yuyobrujo.com/tango-shoes-for-men/",
        },
      ],
    },
  ];
  const milongasData = [
    {
      title: "",
      links: [
        {
          text: "What is a milonga?",
          url: "https://en.wikipedia.org/wiki/Milonga_(dance_event)",
        },
      ],
    },
    {
      title: "Dundee",
      links: [
        {
          text: "Dundee Tango Society FB group",
          url: "https://www.facebook.com/groups/688472064519341",
        },
      ],
    },
    {
      title: "Edinburgh",
      links: [
        {
          text: "List of all milongas (Edinburgh Tango)",
          url: "https://edinburghtango.org.uk/tango-events/?wpv_aux_current_post_id=223&wpv_aux_parent_post_id=223&wpv_sort_orderby=field-wpcf-event-date-start&wpv_sort_order=asc&wpv_sort_orderby_as=numeric&wpv_view_count=1377&wpv-activity=milonga\n",
        },
        {
          text: "Tango Del Jueves FB group",
          url: "https://www.facebook.com/groups/322420071775375",
        },
        {
          text: "Edinburgh Embrace FB group",
          url: "https://www.facebook.com/groups/284975627156635",
        },
      ],
    },
    {
      title: "Aberdeen",
      links: [
        {
          text: "Tango Aberdeen FB group",
          url: "https://www.facebook.com/groups/284975627156635",
        },
      ],
    },
    {
      title: "Glasgow",
      links: [
        {
          text: "Tango in Glasgow FB group",
          url: "https://www.facebook.com/groups/tangoinglasgow/",
        },
      ],
    },
    {
      title: "Inverness(ish)",
      links: [
        {
          text: "Argentine Tango Highland",
          url: "https://www.facebook.com/Argentinetangohighland",
        },
      ],
    },
  ];
  const musicData = [
    {
      title: "Tango Orchestras",
      links: [
        {
          text: "The difference between an orchestra leader and a composer",
          url: "https://tangonotes.com/article-orchestras-explained.html",
        },
        { text: "D'Arienzo", url: "https://tangonotes.com/darienzo.html" },
        { text: "Di Sarli", url: "https://tangonotes.com/disarli.html" },
        { text: "Troilo", url: "https://tangonotes.com/troilo.html" },
        { text: "Pugliese", url: "https://tangonotes.com/pugliese.html" },
      ],
    },
    {
      title: "Playlists",
      links: [
        {
          text: "Tango Tandas (1935-52)",
          url: "https://open.spotify.com/playlist/2A4gVZvMymhaOKiwhoSJC7?si=4196ed72dc244827",
        },
        {
          text: "Vals",
          url: "https://open.spotify.com/playlist/490Hn31OLirjeZCyv6qMuE?si=8cbfc77b27de4015",
        },
        {
          text: "Milonga",
          url: "https://open.spotify.com/playlist/5umLzAaFulrPq1U19layvU?si=064c6ef08e024acf",
        },
        {
          text: "Example milonga playlist",
          url: "https://open.spotify.com/playlist/4G92LE8bXYNyk81MEm3VKQ?si=1e31ed6444994011",
        },
        {
          text: "Example milonga playlist 2",
          url: "https://open.spotify.com/playlist/3WkrP2WFLxYIF80Sy8TnQh?si=6fa93624f79d4eb1",
        },
      ],
    },
    {
      title: "Tango Lyrics and Translations",
      links: [
        { text: "Poesía de gotán", url: "https://poesiadegotan.com/" },
        {
          text: "Tango Poetry Project",
          url: "https://www.tangopoetryproject.com/translations",
        },
      ],
    },
  ];
  const videoData = [
    {
      title: "Tango culture and history",
      links: [
        {
          text: "A guide to your first milonga 🤗",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo93u7AuQ_JO8dmCZralSZD6d&si=DEop1E8VGhJ8zgKR",
        },
        {
          text: "Examples of social dancing",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo932_-Fx6bMXZQFo_WYGSW2B&si=L9-7qnASnTUqFVNW",
        },
        {
          text: "The history of tango",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo90DUi5hbRKgMik2kNEnW7Yt&si=9dzlsp5vrpBbmfAZ",
        },
        {
          text: "The old milongueros",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo92m56VvwrQepVCDglZvvjYJ&si=xWEj0q2xluHTjkSL",
        },
        {
          text: "Tango culture - Documentary",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo92zrb1EDRPWF_qaOQDve3mD&si=Q0fry9vSziRCQZdc",
        },
        {
          text: "Tango interviews",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo93qabq6BF2DEWfQwITOOa7-&si=1UMlElohM6pOz280",
        },
        // {text: '', url: ''}
      ],
    },
    {
      title: "Video Tutorials",
      links: [
        {
          text: "Tango practice videos by Diego",
          url: "https://youtube.com/playlist?list=PLo3FsKx5MJTo0vT2CVyBV6YKlXDUobrB6&si=FdZjQ5LJ7MURpDeI",
        },
        {
          text: "Tango Simplified by Yelizaveta",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo92lMbEUmPA-cjZ0dHbkCGQ-&si=74vaDe-YJbhzkUDk",
        },
        {
          text: "Tango Musicality",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo90PueByOFotVk9jtotH9HdG&si=F5bttIur7-wktxFA",
        },
      ],
    },
    {
      title: "Thoughts on",
      links: [
        {
          text: "Musicality",
          url: "https://www.youtube.com/playlist?list=PLe-wLrsGfo911mUSwq7x33peHGoz5MbSG",
        },
        {
          text: "The rise of double-role dancing",
          url: "https://www.youtube.com/watch?v=1KBHLblkyt8",
        },
      ],
    },
    {
      title: "Performances",
      links: [
        {
          text: "Noelia and Carlitos (in progress)",
          url: "https://youtube.com/playlist?list=PLe-wLrsGfo92fax23P21oiuNdSKsL7MmK&si=2aasetJgI_7KZYHw",
        },
      ],
    },
  ];

  return (
    <section className="useful-links" id="useful-links">
      <h2 className="heading">Useful Links</h2>
      <p className={"instruction"}>
        Click on each of the categories to expand them and see more info
      </p>

      <div className="links-container" id="links-container">
        <LinkDetails
          icon="👠👞"
          title="Tango Shoes"
          sections={tangoShoesData}
        />

        <LinkDetails
          icon="💃🕺"
          title="Milongas across Scotland"
          sections={milongasData}
        />

        <LinkDetails icon="🪗🎻" title="Tango Music" sections={musicData} />

        <LinkDetails
          icon="♥️️ 🎥️"
          title="Youtube Playlists"
          sections={videoData}
        />
      </div>
    </section>
  );
}

export default UsefulLinks;
