// import './App.css';
import { Helmet } from "react-helmet";
import HomePage from "./components/main-page";

function App() {
  return (
    <>
      <Helmet>
        <title>
          Argentine Tango Dance Classes in Dundee | Dundee Argentine Tango
        </title>
        <meta
          name="description"
          content="Join our Argentine Tango dance classes in Dundee. Learn, dance, and enjoy social dance opportunities with Dundee Argentine Tango."
        />
        <meta
          name="keywords"
          content="tango, argentine tango, dance, class, dundee, dancing, dance classes, dance classes for adults, Dundee dance classes"
        />
        <meta name="author" content="Dundee Argentine Tango" />
        <meta property="og:title" content="Dundee Argentine Tango" />
        <meta
          property="og:description"
          content="Local social dancers sharing the joy of Argentine Tango in Dundee 💃🕺 Join us in creating awesome social dance opportunities"
        />
        <meta property="og:url" content="https://dundeeargentinetango.co.uk" />
        <meta
          property="og:image"
          content="https://dundee-argentine-tango.web.app/embrace.jpg"
        />
        <meta name="twitter:card" content="%PUBLIC_URL%/embrace.jpg" />
      </Helmet>

      <div className="App">
        <HomePage />
      </div>
    </>
  );
}

export default App;
