import React, { useState } from "react";
import "./landing-page.scss";
import { Button } from "../ui/button";

import NewsletterSignup from "../about/newsletter-signup";
import { SocialMediaMenu } from "../social-media/social-media-menu"; // Make sure to create a corresponding CSS file

export const LandingPageTile = () => {
  const [signedUpForNewsletter, setSignedUpForNewsletter] = useState(false);

  return (
    <div className="landing-page-container">
      <div
        className="landing-page-tile-container"
        style={{ background: "#f4e1d2" }}
      >
        <div className={"columns"}>
          <div className={"column"}>
            <div className={"image-container"}>
              <img className={"image"} src={"./1.jpeg"} alt={"embrace"} />
            </div>
          </div>
          <div className={"column"}>
            <div className={"text-container"}>
              <h1>Who We Are</h1>
              <p>
                We're a community-driven crew of local social dancers who share
                the joy of Argentine Tango with the city of Dundee. Each member
                of our team is passionate about tango and equally enthusiastic
                about sharing their knowledge with others. Check out our social
                media accounts to learn more about us.
              </p>
              <SocialMediaMenu color={"var(--deep-red)"} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="landing-page-tile-container"
        style={{ background: "white" }}
      >
        <div className={"columns"}>
          <div className={"column"}>
            <div className={"text-container"}>
              <h1>Get Involved </h1>
              <p>
                We encourage you to get involved and take part in what we do.
                Whether you're a curious beginner or an experienced dancer,
                there's a place for you here. Our friendly team welcomes
                complete beginners and we are always happy to give people a
                tango crash course. If you are not sure what Argentine Tango is
                come to our events and see for yourself or check out our YouTube
                channel for some inspiration.
              </p>

              <Button
                title={"Look at our upcoming events"}
                href={"#comingUp"}
                primary
                styleType={"secondary"}
              />
            </div>
          </div>
          <div className={"column"}>
            <div className={"image-container"}>
              <img className={"image"} src={"./2.jpeg"} alt={"embrace"} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="landing-page-tile-container"
        style={{ background: "#f4e1d2" }}
      >
        <div className={"columns"}>
          <div className={"column"}>
            <div className={"image-container"}>
              <img className={"image"} src={"./3.jpeg"} alt={"embrace"} />
            </div>
          </div>
          <div className={"column"}>
            <div className={"text-container"}>
              {/*//TODO: Major todo, udpate this*/}
              <h1> Stay Connected</h1>
              <p>
                Follow us on social media, so you don't miss out on our upcoming
                events. If social media isn't your thing, drop your email down
                below to subscribe and stay updated with all our exciting
                classes, events, and resources.
              </p>
              {/*Download our free PDF guide to*/}
              {/*tango and start your journey today! Plus, book now for our*/}
              {/*upcoming workshops and dive deeper into the world of tango.*/}
              {!signedUpForNewsletter ? (
                <NewsletterSignup
                  onSuccess={() => setSignedUpForNewsletter(true)}
                />
              ) : (
                <div className={"newsletter-signup-success-message"}>
                  <span style={{ fontWeight: "600" }}>
                    🎉🎉🎉 It worked 🎉🎉🎉 <br />
                  </span>
                  Thanks for signing up! You'll hear from us soon 😎
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
